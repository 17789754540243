// src/data/experiences.js

const experiences = [
    {
      company: "C2SMARTER Center",
      position: "Developer Intern",
      projects: [
        {
          title: "Traffic-doc Chatbot",
          image: "KC_Demo.gif",
          description: `Engineered an interactive chatbot using Python, Flask, React, Docker, Redis, ChromaDB, RAG, and led the finetuning of the Mistral 7B model to specialize it for ITS project content within a distributed system architecture, enabling semantic search across ITS project documents and contributing to a USDOT-aligned knowledge base.`,
          demoLink: "https://kc-chatbot.c2smarter-it.org/"
        },
        {
          title: "Data Warehouse",
          image: "DW_Demo.gif",
          description: `Developing a data warehouse web application featuring an online chat system using Docker, React, MongoDB, and MySQL, employing RESTful API design and microservices architecture for modularity and ease of maintenance.`,
        },
        {
          title: "Traffic Management System",
          image: "TMS_Demo.gif",
          description: `Developing a vision-based solution with Python and Next.js for PANYNJ at JFK Airport to automatically detect queue lengths and manage curb lanes, visualizing real-time traffic on a 2D plane for Terminal 4. Leveraging AI-driven assessments to evaluate mobility and safety impacts of frontage modifications and construction zones.`,
        },
      ]
    },
    {
      company: "PAIR Lab",
      position: "Research Assistant",
      projects: [
        {
          title: "Image Cropping App",
          image: "Crop_Demo.gif",
          description: `Developed a multi-platform semi-automatic image cropping application for Windows and Mac with Python by implementing contour detection algorithms.`,
          githubLink: "https://github.com/dlz01/PAIR-cropping-tool"
        },
      ]
    },
    {
      company: "Future of Programming Lab",
      position: "Research Assistant",
      projects: [
        {
          title: "Web-based Programming Environment",
          image: "Hazel_Demo.gif",
          description: `Developed a web-based programming environment integrating OCaml and Javascript, providing an interactive learning platform for students in an undergraduate programming course at the University of Michigan.`,
          demoLink: "https://hazel.org/build/dev/"
        },
      ]
    },
    {
      company: "Personal Projects",
      projects: [
        {
          title: "SideWalk Detection",
          image: "Sidewalk_Demo.gif",
          description: `Fine-tuned the Segment Anything Model (SAM) on a custom remote sensing dataset focused on sidewalk segmentation, developed a user-facing web application using Shiny for Python, allowing real-time segmentation visualization and interaction with sidewalk detection outputs.`,
        },
        {
          title: "Smart Home Energy Management System",
          image: "SHEMS_Demo.png",
          description: `Designed and implemented an efficient MySQL database schema to support secure registration, login, and personal data management for multiple users, ensuring data integrity through advanced locking mechanisms for concurrent access.
          Created a responsive and intuitive React-based interface for seamless account management, real-time device monitoring, and data analytics.`
        },
      ]
    },
  ];
  
  export default experiences;
  