// src/components/Experience.js
import React from "react";
import experiences from "../data/experiences";
import "./Experience.css"
import Projects from "./Projects";

const Experience = () => (
    <section className="light-section container-fluid" id="experience">
      <h1>Experience</h1>
      {experiences.map((exp, index) => (
        <div key={index} className="experience">
          <h5><strong>{exp.company}</strong></h5>
          {exp.position && <h6><em>{exp.position}</em></h6>}
          <Projects projects={exp.projects}/>
        </div>
      ))}
    </section>
  );

export default Experience;
