import React from 'react';

function Footer() {
    var currentYear = new Date().getFullYear();
    return <footer className="light-section" id="footer">
        <div className="container-fluid center-section">
            <p>© Copyright {currentYear} Donglin Zhou</p>
        </div>
    </footer>
}

export default Footer;