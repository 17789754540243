import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
// import Projects from './components/Projects';
import Educations from './components/Educations';
import Summary from './components/Summary';
import Experience from './components/Experience';

function App() {
    return (
        <div>
            <Navbar />

            <Summary />

            <Educations theme="white-section" />

            <Experience />

            <Footer />
        </div>
    );
}

export default App;
