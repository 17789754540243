// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Experience.css */

.experience {
  margin-bottom: 4rem;
  text-align: center;
}
/* 
#experience {
  text-align: center;
} */

.experience h6 {
  margin-top: 0;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Always three columns */
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 33%fr)); */
  gap: 1rem;
  margin-top: 1rem;
}

.project-grid-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  min-width: 250px;
}

.project-image {
  width: calc(100% - 20px);
  height: auto;
  border-radius: 8px;
  margin: 10px;
}

.project-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.project-info h5 {
  margin: 0;
}

.project-info a {
  color: #007acc;
  text-decoration: none;
  margin-top: 0.5rem;
}

.project-info a:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr)
  }
}

@media (max-width: 600px) {
  .projects-grid {
    grid-template-columns: repeat(1, 1fr)
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Experience.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;;;GAGG;;AAEH;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,qCAAqC,EAAE,yBAAyB;EAChE,mEAAmE;EACnE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;IACE;EACF;AACF;;AAEA;EACE;IACE;EACF;AACF","sourcesContent":["/* src/components/Experience.css */\n\n.experience {\n  margin-bottom: 4rem;\n  text-align: center;\n}\n/* \n#experience {\n  text-align: center;\n} */\n\n.experience h6 {\n  margin-top: 0;\n}\n\n.projects-grid {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr); /* Always three columns */\n  /* grid-template-columns: repeat(auto-fit, minmax(250px, 33%fr)); */\n  gap: 1rem;\n  margin-top: 1rem;\n}\n\n.project-grid-item {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  overflow: hidden;\n  min-width: 250px;\n}\n\n.project-image {\n  width: calc(100% - 20px);\n  height: auto;\n  border-radius: 8px;\n  margin: 10px;\n}\n\n.project-info {\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n\n.project-info h5 {\n  margin: 0;\n}\n\n.project-info a {\n  color: #007acc;\n  text-decoration: none;\n  margin-top: 0.5rem;\n}\n\n.project-info a:hover {\n  text-decoration: underline;\n}\n\n@media (max-width: 1024px) {\n  .projects-grid {\n    grid-template-columns: repeat(2, 1fr)\n  }\n}\n\n@media (max-width: 600px) {\n  .projects-grid {\n    grid-template-columns: repeat(1, 1fr)\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
