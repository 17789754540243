// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#navbar a {
    text-decoration: none;
    align-items: center;
}

#navbar h3 {
    text-decoration: none;
    color: white;
    margin: 0;
}

#navbar p {
    text-decoration: none;
    color: white;
    font-weight: 100;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;IAChB,SAAS;AACb","sourcesContent":["#navbar a {\n    text-decoration: none;\n    align-items: center;\n}\n\n#navbar h3 {\n    text-decoration: none;\n    color: white;\n    margin: 0;\n}\n\n#navbar p {\n    text-decoration: none;\n    color: white;\n    font-weight: 100;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
