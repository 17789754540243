import React from 'react';

const Projects = ({ projects }) => {
    return <div className="projects-grid">
        {projects.map((project, idx) => (
            <div key={idx} className="project-grid-item">
                <img src={project.image} alt={project.title} className="project-image" />
                <div className="project-info">
                    <h5>{project.title}</h5>
                    <p>{project.description}</p>
                    {project.demoLink && <a href={project.demoLink} target="_blank" rel="noopener noreferrer">
                        Link
                    </a>}
                    {project.githubLink && <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                        GitHub
                    </a>}
                </div>
            </div>
        ))}
    </div>
}

export default Projects;